import { forwardRef } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const DownloadButton = forwardRef(({ title }, ref) => (
  <Tooltip title={title} placement="top">
    <IconButton
      ref={ref}
      className="download-template-button"
      sx={{ width: "48px", height: "48px" }}
    >
      <FileDownloadIcon />
    </IconButton>
  </Tooltip>
));

DownloadButton.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DownloadButton;
