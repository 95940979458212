// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Box,
  CircularProgress,
  Grid,
  Typography,
  Divider,
  FormControl,
  TextField,
  Button,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import REGULATIONS_CONSTANTS from "../../constants/Regulations/Regulations";
import GENERIC_CONSTANTS from "../../constants/Regulations/Generic";
import UPLOAD_CONSTANTS from "../../constants/Regulations/PartsUpload";
import getApiHeaders from "../../services/utils.js/apiHeaders";
import RegulationApi from "../../services/RegulationsApi";
import DownloadTemplateButton from "../../components/DownloadTemplateButton";
import UploadPartListButton from "../../components/UploadPartListButton";
import {
  clearRegulationFormData,
  setUpdatedRegulationData,
} from "../../redux/regulationMaintenanceSlice";
import "./updateRegulations.css";
import getApiUrls from "../../services/utils.js/apiUrls";
import REGULATIONS_API_BASE from "../../services/axiosApi";
import {
  getFormattedDate,
  mergeAndHandleRegulationParts,
  getChunkParts,
  createChunkQuery,
} from "./utils/regulationUtils";
import DownloadButton from "../../components/DownloadButton";

const updatedRegulations = ({ onCloseEditModal, isOpen }) => {
  const getBackendUrl = getApiUrls();
  const dispatch = useDispatch();
  const { editRegulationFormData, regulationParts, uploadPartsError } = useSelector(
    (state) => state.regulationMaintenanceForm,
  );
  const { authxToken, userName } = useSelector(
    (state) => state.loginToken,
  );
  const [spinner, setSpinner] = useState(false);
  const [showApiFailure, setShowApiFailure] = useState(false);
  const [showApiInfo, setShowApiInfo] = useState(false);
  const [apiFailureMessage, setApiFailureMessage] = useState("");
  const [showApiSuccess, setShowApiSuccess] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState("");
  const [apiInfoMessage, setApiInfoMessage] = useState("");
  const [effDatePickerVal, setEffDatePickerVal] = useState("");
  const [updatedRegulation, setUpdatedRegulation] = useState(
    editRegulationFormData,
  );
  const [initialRegulation, setInitialRegulation] = useState(
    editRegulationFormData,
  );
  const [filteredRegulatedParts, setFilteredRegulatedParts] = useState({});
  const [partsSearchString, setPartsSearchString] = useState("");
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [isDataLossAlert, setIsDataLossAlert] = useState(false);
  const [partItemIds, setPartItemIds] = useState([]);
  const [invalidRows, setInvalidRows] = useState(new Set());
  const [updatedParts, setUpdatedParts] = useState([]);
  const [isValidationInProgress, setIsValidationInProgress] = useState(false);

  const handleDialogOpen = (dialogOpen) => {
    setIsDataLossAlert(dialogOpen);
  };

  const handleAlertClose = () => {
    setShowApiFailure(false);
    setApiFailureMessage("");
    setApiSuccessMessage("");
  };

  const handleDataDiscard = () => {
    dispatch(clearRegulationFormData());
    setIsFormUpdated(false);
    handleDialogOpen(false);
    handleAlertClose();
    onCloseEditModal();
  };

  const handleCloseEditModal = () => {
    if (isFormUpdated) {
      handleDialogOpen(true);
    } else {
      dispatch(clearRegulationFormData());
      handleAlertClose();
      onCloseEditModal();
    }
  };

  const handleSearchParts = (event) => {
    const partKeyword = event.target.value;
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (partKeyword === "" || regex.test(partKeyword)) {
      setPartsSearchString(partKeyword);
    } else {
      return;
    }
    if (partKeyword) {
      const filteredRegulationParts = updatedRegulation.parts.filter(
        (item) => (`${item.lineAbbrev}${item.partNo}`).toLowerCase()
          .includes(partKeyword.toLowerCase().replaceAll(" ", ""))
          || item.partDesc.toLowerCase().includes(partKeyword.toLowerCase()),
      );
      const filteredupdatedRegulation = {
        ...updatedRegulation,
        parts: filteredRegulationParts,
      };
      setFilteredRegulatedParts(filteredupdatedRegulation);
    } else {
      setFilteredRegulatedParts(updatedRegulation);
    }
  };

  function removeDuplicatesPartsRows(fPartsData) {
    const newUpdatedPartsArray = [];
    const uniquePartsObject = {};

    Object.keys(fPartsData).forEach((key) => {
      if (fPartsData[key]?.id) {
        const UpdatedNewParts = fPartsData[key]?.id;
        uniquePartsObject[UpdatedNewParts] = fPartsData[key];
      }
    });
    Object.keys(uniquePartsObject).forEach((key) => {
      newUpdatedPartsArray.push(uniquePartsObject[key]);
    });

    return newUpdatedPartsArray;
  }

  const callUpdateRegulation = () => {
    const headers = getApiHeaders(authxToken.access_token);
    const config = {
      ...headers,
    };
    const apiCallback = (response) => {
      if (response.status === 200) {
        if (response && response.data && response.data.regId) {
          setSpinner(false);
          handleAlertClose();
          dispatch(clearRegulationFormData());
          dispatch(setUpdatedRegulationData({ value: response.data }));
          onCloseEditModal();
        }
      } else {
        setSpinner(false);
        setShowApiFailure(true);
        setApiFailureMessage(REGULATIONS_CONSTANTS.UPDATE_API_FAILURE);
      }
    };

    const uniqueModifiedParts = removeDuplicatesPartsRows(updatedParts)
      .filter((part) => (part.regId && part.modified) || !part.regId);

    RegulationApi.saveRegulation(updatedRegulation, config, apiCallback, "update", uniqueModifiedParts);
  };

  const handleUpdateAndSubmit = () => {
    setSpinner(true);
    callUpdateRegulation();
  };

  const updatePartDetails = (params, val) => {
    const updatedPartList = [...filteredRegulatedParts.parts];
    // eslint-disable-next-line max-len
    const updatedPartIndex = filteredRegulatedParts.parts.findIndex((each) => each.id === params.id);
    updatedPartList[updatedPartIndex] = {
      ...updatedPartList[updatedPartIndex],
      [params.field]: val,
      modifiedBy: userName,
      modified: true,
    };

    const upParts = [];
    updatedPartList.forEach((each) => {
      if (each.id === params.id || !each.regId) {
        upParts.push(each);
      }
    });

    const updatedNewParts = [...updatedParts, ...upParts];
    const updatedNewArryObject = {};

    Object.keys(updatedNewParts).forEach((key) => {
      if (updatedNewParts[key].id) {
        updatedNewArryObject[updatedNewParts[key].id] = updatedNewParts[key];
      }
    });
    const updatedNewPartsData = [];
    Object.keys(updatedNewArryObject).forEach((key) => {
      updatedNewPartsData.push(updatedNewArryObject[key]);
    });

    setUpdatedParts([...regulationParts, ...updatedNewPartsData]);

    const updatedRegAfterUpdate = {
      ...updatedRegulation,
      parts: updatedPartList,
    };
    setFilteredRegulatedParts(updatedRegAfterUpdate);
  };

  const handleDatePickerSelection = (val, params) => {
    if (val) {
      setIsFormUpdated(true);
      let formattedDateVal = getFormattedDate(val);
      if (formattedDateVal === "Invalid Date") {
        formattedDateVal = null;
      }
      if (params.field === "effDate") {
        setEffDatePickerVal(val);
        setUpdatedRegulation({
          ...updatedRegulation,
          [params.field]: formattedDateVal,
          modifiedBy: userName,
          modified: true,
        });
      } else if (params.field === "expiredDate" || params.field === "obsoleteDate") {
        updatePartDetails(params, formattedDateVal);
      }
    }
  };

  const handleFormUpdateByKey = (e, params) => {
    const val = e.target.value;
    if (params.field === "bestClass") {
      updatePartDetails(params, val);
    } else {
      setUpdatedRegulation({
        ...updatedRegulation,
        [params.field]: val,
        modifiedBy: userName,
        modified: true,
      });
    }
    setIsFormUpdated(true);
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
  };

  const getRegulatedPartsForGrid = () => {
    const regulatedPartsForGrid = updatedRegulation.parts.map((each) => ({
      ...each,
      id: `${each.lineAbbrev}-${each.partNo}`,
      createdDate: each.createdDate ? getFormattedDate(each.createdDate) : "",
      modifiedDate: each.modifiedDate ? getFormattedDate(each.modifiedDate) : "",
    }));
    const filteredUpdatedRegulation = {
      ...updatedRegulation,
      parts: regulatedPartsForGrid,
    };

    if (dayjs && typeof dayjs === "function") {
      setEffDatePickerVal(dayjs(editRegulationFormData.effDate));
    }
    setInitialRegulation(filteredUpdatedRegulation);
    setUpdatedRegulation(filteredUpdatedRegulation);
    setFilteredRegulatedParts(filteredUpdatedRegulation);
  };

  const validatePartsByCatalog = () => {
    const regulatedPartsPID = regulationParts.map((each) => ((each.lineAbbrev).length === 2 ? `${each.lineAbbrev}_${each.partNo}` : each.lineAbbrev + each.partNo));
    setPartItemIds(regulatedPartsPID);
  };

  const processUploadedPartsData = (parts) => {
    const mergedParts = parts;
    const regulationPartsData = regulationParts.map((each) => ({
      ...each,
      id: `${each.lineAbbrev}-${each.partNo}`,
      createdDate: each.createdDate ? getFormattedDate(each.createdDate) : "",
      createdBy: userName,
    }));

    mergedParts.forEach((updatedPart, index) => {
      const initialPart = initialRegulation.parts.find(
        (item) => item.partNo === updatedPart.partNo && item.lineAbbrev === updatedPart.lineAbbrev,
      );

      if (initialPart && updatedPart.regId) {
        let isModified = false;
        const fieldsToCheck = [
          REGULATIONS_CONSTANTS.ALT_LINE_ABBR,
          REGULATIONS_CONSTANTS.ALT_PART_NO,
          REGULATIONS_CONSTANTS.ALT_PART_DESC,
          REGULATIONS_CONSTANTS.BEST_CLASS,
          REGULATIONS_CONSTANTS.EXPIRED_DATE,
          REGULATIONS_CONSTANTS.OBSOLETE_DATE,
        ];

        fieldsToCheck.forEach((field) => {
          if (mergedParts[index][field] !== initialPart[field]
              && !(mergedParts[index][field] == null && initialPart[field] == null)) {
            isModified = true;
          }
        });

        if (isModified) {
          mergedParts[index].modifiedBy = userName;
          mergedParts[index].modified = true;
        }
      }
    });

    setUpdatedParts([...regulationPartsData, ...mergedParts]);
  };

  const isNextChunk = (index, chunkSize) => chunkSize > 0 && index % chunkSize === 0;

  function resetMessages() {
    setApiFailureMessage(UPLOAD_CONSTANTS.MESSAGES.EMPTY);
    setApiInfoMessage(UPLOAD_CONSTANTS.MESSAGES.EMPTY);
    setApiSuccessMessage(UPLOAD_CONSTANTS.MESSAGES.EMPTY);
  }

  function resetPartsUploadState() {
    setShowApiFailure(false);
    setShowApiInfo(false);
    setShowApiSuccess(false);
    resetMessages();
    setInvalidRows(new Set());
    setIsValidationInProgress(true);
    setUpdatedRegulation(initialRegulation);
  }

  function evaluateUploadStatus(invalidRowsIds) {
    const totalPartsCount = partItemIds.length;
    const invalidPartsCount = invalidRowsIds.size;

    if (invalidPartsCount === 0) {
      return UPLOAD_CONSTANTS.STATUS.SUCCESS;
    }

    if (invalidPartsCount === totalPartsCount) {
      return UPLOAD_CONSTANTS.STATUS.FAILURE;
    }

    return UPLOAD_CONSTANTS.STATUS.PARTIAL_FAILURE;
  }

  const showMessage = (status, invalidRowsIds, regulation) => {
    resetMessages();

    switch (status) {
      case UPLOAD_CONSTANTS.STATUS.SUCCESS:
        setApiSuccessMessage(UPLOAD_CONSTANTS.MESSAGES.SUCCESS(partItemIds.length));
        setShowApiSuccess(true);
        setInitialRegulation(regulation.value);
        setUpdatedRegulation(regulation.value);
        setFilteredRegulatedParts(regulation.value);
        break;

      case UPLOAD_CONSTANTS.STATUS.FAILURE:
        setApiFailureMessage(UPLOAD_CONSTANTS.MESSAGES.FAILURE(invalidRowsIds.size));
        setShowApiFailure(true);
        break;

      case UPLOAD_CONSTANTS.STATUS.PARTIAL_FAILURE:
        // eslint-disable-next-line max-len
        setApiFailureMessage(UPLOAD_CONSTANTS.MESSAGES.PARTIAL_FAILURE(invalidRowsIds.size, partItemIds.length));
        setShowApiFailure(true);
        setIsFormUpdated(false);
        break;

      default:
        break;
    }

    return true;
  };

  const processCatalogResponses = (catalogPromises, invalidRowsIds, regulation) => {
    Promise.all(catalogPromises)
      .then(() => {
        setInvalidRows(invalidRowsIds);
        const status = evaluateUploadStatus(invalidRowsIds);
        showMessage(status, invalidRowsIds, regulation);
        setIsValidationInProgress(false);
      })
      .catch((error) => {
        setApiFailureMessage(error.message || UPLOAD_CONSTANTS.MESSAGES.UNEXPECTED_ERROR);
        setShowApiFailure(true);
        setIsValidationInProgress(false);
      });
  };

  const processAndValidateParts = (chunkParts, invalidRowsIds, catalogParts, existingPartIds) => {
    const catalogValidatedParts = new Map();
    catalogParts.forEach((each) => {
      catalogValidatedParts.set(`${each.lineAbbreviation}-${each.partNumber}`, each);
      catalogValidatedParts.set(`${each.hqAbbreviation}-${each.partNumber}`, each);
    });

    chunkParts.forEach((fPart) => {
      const partId = `${fPart.lineAbbrev}-${fPart.partNo}`;
      const validPart = catalogValidatedParts.get(partId);

      const isNewPart = !existingPartIds.includes(partId);
      const isDateInvalid = fPart.obsoleteDate && getFormattedDate(fPart.obsoleteDate) === "Invalid Date";
      const isAbbrevMismatch = validPart && (validPart.lineAbbreviation !== fPart.lineAbbrev);
      if ((!validPart || isAbbrevMismatch || isDateInvalid) && isNewPart) {
        invalidRowsIds.add(partId);
      }

      // eslint-disable-next-line no-param-reassign
      fPart.partDesc = validPart ? validPart.title : fPart.partDesc;

      if (validPart && isNewPart) {
        // eslint-disable-next-line no-param-reassign
        fPart.createdBy = userName;
      }
    });
  };

  const createCatalogPromises = (invalidRowsIds, regulationWrapper) => {
    const catalogParts = [];
    const chunkSize = UPLOAD_CONSTANTS.CHUNK_SIZE;
    const existingPartIds = initialRegulation.parts.map((part) => `${part.lineAbbrev}-${part.partNo}`);

    return partItemIds.reduce((promises, _, index) => {
      if (isNextChunk(index, chunkSize)) {
        const queryData = createChunkQuery(partItemIds, chunkSize, index);

        promises.push(
          REGULATIONS_API_BASE.post(`${getBackendUrl}${UPLOAD_CONSTANTS.CATALOG_PATH}`, { query: queryData })
            .then((response) => {
              if (response.data.errors) {
                throw new Error(UPLOAD_CONSTANTS.MESSAGES.CATALOG_ERROR);
              }

              const hasValidItems = (itemLookUp) => itemLookUp?.items
                      && Array.isArray(itemLookUp.items) && itemLookUp.items.length > 0;

              if (response.data.data.itemLookUp) {
                const { itemLookUp } = response.data.data;
                if (hasValidItems(itemLookUp)) {
                  itemLookUp.items.forEach((item) => catalogParts.push(item));
                }

                // eslint-disable-next-line max-len
                const chunkParts = getChunkParts(filteredRegulatedParts.parts, partItemIds, chunkSize, index);
                processUploadedPartsData(chunkParts);
                // eslint-disable-next-line max-len
                processAndValidateParts(chunkParts, invalidRowsIds, catalogParts, existingPartIds);
              }

              const filterPartsData = { ...updatedParts, parts: updatedRegulation.parts };
              setUpdatedParts([...filterPartsData.parts]);
              // eslint-disable-next-line no-param-reassign
              regulationWrapper.value = {
                ...updatedRegulation,
                parts: [...filterPartsData.parts],
              };
            }),
        );
      }
      return promises;
    }, []);
  };

  useEffect(() => {
    if (partItemIds?.length > 0) {
      const invalidRowsIds = new Set();
      const regulation = { value: null };
      const catalogPromises = createCatalogPromises(invalidRowsIds, regulation);

      resetPartsUploadState();
      processCatalogResponses(catalogPromises, invalidRowsIds, regulation);
    }
  }, [partItemIds]);

  useEffect(() => {
    if (uploadPartsError && Object.keys(uploadPartsError)[0] === "regulationParts" && uploadPartsError.regulationParts) {
      const errorMsg = uploadPartsError.regulationParts
        ? uploadPartsError.regulationParts : REGULATIONS_CONSTANTS.UNABLE_READ_FILE;
      setApiFailureMessage(errorMsg);
      setShowApiFailure(true);
    }
  }, [uploadPartsError]);

  useEffect(() => {
    if (regulationParts && regulationParts.length > 0) {
      setIsFormUpdated(true);
      const mergedParts = mergeAndHandleRegulationParts(updatedRegulation.parts, regulationParts);

      setUpdatedRegulation({
        ...updatedRegulation,
        parts: mergedParts,
      });
      setFilteredRegulatedParts({
        ...updatedRegulation,
        parts: mergedParts,
      });

      handleAlertClose();
      validatePartsByCatalog();
    }
  }, [regulationParts]);

  useEffect(() => {
    getRegulatedPartsForGrid();
  }, []);

  const createdDatePickerVal = updatedRegulation.createdDate
    ? getFormattedDate(updatedRegulation.createdDate)
    : "";
  const modifiedDatePickerVal = updatedRegulation.modifiedDate
    ? getFormattedDate(updatedRegulation.modifiedDate)
    : "";
  const {
    REGULATION_HEADER_LABELS,
    REGULATION_PARTS_LABELS,
    REGULATION_DETAILS_HEADER_LABELS,
    REG_CLASS_CODES,
  } = REGULATIONS_CONSTANTS;

  const detailsTableColumns = [
    {
      field: REGULATIONS_CONSTANTS.REG_TITLE,
      headerName: REGULATION_HEADER_LABELS[0],
      width: 300,
    },
    {
      field: REGULATIONS_CONSTANTS.REG_DESC,
      headerName: REGULATION_DETAILS_HEADER_LABELS[0],
      width: 250,
      renderCell: (params) => (
        <TextField
          id="update-reg-desc"
          className="editableTextField"
          color="warning"
          variant="standard"
          focused
          value={params.value}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => handleFormUpdateByKey(e, params)}
        />
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.RULE_NO,
      headerName: REGULATION_HEADER_LABELS[1],
      width: 110,
      renderCell: (params) => (
        <TextField
          id="update-reg-rule"
          className="editableTextField"
          color="warning"
          variant="standard"
          focused
          value={params.value}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => handleFormUpdateByKey(e, params)}
        />
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.REG_JURISDICTION_LEVEL,
      headerName: REGULATION_HEADER_LABELS[2],
      width: 120,
    },
    {
      field: REGULATIONS_CONSTANTS.STATE_CODE,
      headerName: REGULATION_HEADER_LABELS[3],
      width: 100,
    },
    {
      field: REGULATIONS_CONSTANTS.EFF_DATE,
      headerName: REGULATION_HEADER_LABELS[8],
      width: 175,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label=""
            value={effDatePickerVal}
            format="YYYY-MM-DD"
            onChange={(val) => handleDatePickerSelection(val, params)}
            slotProps={{ field: { clearable: true, onClear: (val) => handleDatePickerSelection(val, params) }, textField: { variant: "standard", color: "warning", focused: true } }}
          />
        </LocalizationProvider>
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.REG_TYPE,
      headerName: REGULATION_HEADER_LABELS[4],
      width: 120,
    },
    {
      field: REGULATIONS_CONSTANTS.CREATED_DATE,
      headerName: REGULATION_DETAILS_HEADER_LABELS[6],
      width: 135,
      renderCell: () => (<span>{createdDatePickerVal}</span>),
    },
    {
      field: REGULATIONS_CONSTANTS.CREATED_BY,
      headerName: REGULATION_DETAILS_HEADER_LABELS[7],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.MODIFIED_DATE,
      headerName: REGULATION_DETAILS_HEADER_LABELS[8],
      width: 135,
      renderCell: () => (<span>{modifiedDatePickerVal}</span>),
    },
    {
      field: REGULATIONS_CONSTANTS.MODIFIED_BY,
      headerName: REGULATION_DETAILS_HEADER_LABELS[9],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.REG_COUNTRY,
      headerName: REGULATION_DETAILS_HEADER_LABELS[10],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.REG_COUNTY,
      headerName: REGULATION_DETAILS_HEADER_LABELS[11],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.REG_CITY,
      headerName: REGULATION_DETAILS_HEADER_LABELS[12],
      width: 130,
    },
  ];

  const partsTableColumns = useMemo(() => [
    {
      field: REGULATIONS_CONSTANTS.PART_ABBR,
      headerName: REGULATIONS_CONSTANTS.FIELD_LINE,
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.PART_NO,
      headerName: REGULATION_PARTS_LABELS[1],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.PART_DESC,
      headerName: REGULATION_PARTS_LABELS[2],
      width: 250,
    },
    {
      field: REGULATIONS_CONSTANTS.EXPIRED_DATE,
      headerName: REGULATION_PARTS_LABELS[12],
      width: 175,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label=""
            value={params.value
              ? dayjs(params.value) : ""}
            format="YYYY-MM-DD"
            onChange={(val) => handleDatePickerSelection(val, params)}
            slotProps={{ field: { clearable: true, onClear: (val) => handleDatePickerSelection(val, params) }, textField: { variant: "standard", color: "warning", focused: true } }}
          />
        </LocalizationProvider>
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.OBSOLETE_DATE,
      headerName: REGULATION_PARTS_LABELS[3],
      width: 175,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label=""
            value={params.value
              ? dayjs(params.value) : ""}
            format="YYYY-MM-DD"
            onChange={(val) => handleDatePickerSelection(val, params)}
            slotProps={{ field: { clearable: true, onClear: (val) => handleDatePickerSelection(val, params) }, textField: { variant: "standard", color: "warning", focused: true } }}
          />
        </LocalizationProvider>
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.BEST_CLASS,
      headerName: REGULATION_PARTS_LABELS[11],
      width: 150,
      renderCell: (params) => (
        <TextField
          id="update-reg-classCode"
          select
          value={(params && params.value) ? params.value.toUpperCase() : ""}
          onChange={(e) => handleFormUpdateByKey(e, params)}
          variant="standard"
          color="warning"
          focused
          fullWidth
        >
          {REG_CLASS_CODES
          && REG_CLASS_CODES.map((eachCode) => (
            <MenuItem
              key={`regClassCode-${eachCode}`}
              value={eachCode}
            >
              {eachCode}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.ALT_LINE_ABBR,
      headerName: REGULATION_PARTS_LABELS[4],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.ALT_PART_NO,
      headerName: REGULATION_PARTS_LABELS[5],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.ALT_PART_DESC,
      headerName: REGULATION_PARTS_LABELS[6],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.CREATED_DATE,
      headerName: REGULATION_PARTS_LABELS[7],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.CREATED_BY,
      headerName: REGULATION_PARTS_LABELS[8],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.MODIFIED_DATE,
      headerName: REGULATION_PARTS_LABELS[9],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.MODIFIED_BY,
      headerName: REGULATION_PARTS_LABELS[10],
      width: 150,
    },
  ], [filteredRegulatedParts.parts]);

  const getRowClassName = (params) => (invalidRows.has(params.row.id) ? "inValidRow" : "");

  return (
    <div className="update-regulations-container">
      <Drawer
        sx={{ width: "80% !important" }}
        anchor="right"
        open={isOpen}
        onClose={onCloseEditModal}
      >
        {spinner && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!spinner && (
          <div>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <CloseIcon onClick={handleCloseEditModal} />
            </Grid>
            {showApiSuccess && (
              <Alert
                severity="success"
                variant="outlined"
                sx={{ padding: "25px", margin: "20px" }}
                onClose={handleAlertClose}
              >
                {apiSuccessMessage}
              </Alert>
            )}
            {showApiFailure && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{ padding: "25px", margin: "20px" }}
                onClose={handleAlertClose}
              >
                {apiFailureMessage}
              </Alert>
            )}
            {showApiInfo && (
              <Alert
                severity="info"
                variant="outlined"
                sx={{ padding: "25px", margin: "20px" }}
                onClose={handleAlertClose}
              >
                {apiInfoMessage}
              </Alert>
            )}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <div className="update-regulation-form">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="stepper-content-title"
                  >
                    {REGULATIONS_CONSTANTS.UPDATE_REGULATION}
                  </Typography>

                  <DownloadButton
                    title={REGULATIONS_CONSTANTS.DOWNLOAD_PARTS_FOR_SELECTED_REGULATION}
                  />
                </Box>
                <Divider />
                {updatedRegulation && updatedRegulation.regId && (
                  <div>
                    <div className="regulaion-details-section">
                      <p className="stepper-content-subTitle">
                        {`${REGULATIONS_CONSTANTS.STEPPER_TITLES_EDIT[0]} - ${REGULATIONS_CONSTANTS.STEPPER_SUB_TITLES_EDIT[0]}`}
                      </p>
                      <DataGrid
                        rows={[updatedRegulation]}
                        columns={detailsTableColumns}
                        hideFooter
                        hideFooterPagination
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                        disableColumnSelector
                        disableColumnMenu
                        autoHeight
                      />
                    </div>
                    <Divider />
                    <div className="update-regulated-parts-section">
                      <p className="stepper-content-subTitle">
                        {`${REGULATIONS_CONSTANTS.STEPPER_TITLES_EDIT[1]} - ${REGULATIONS_CONSTANTS.STEPPER_SUB_TITLES_EDIT[1]}`}
                      </p>
                      <div>
                        <div className="update-parts-filter-section">
                          {updatedRegulation.parts && (
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                          >
                            <FormControl
                              sx={{ width: 500, backgroundColor: "#fff", padding: "0px" }}
                            >
                              <TextField
                                id="reg-search"
                                type="search"
                                variant="standard"
                                value={partsSearchString}
                                onChange={handleSearchParts}
                                label={
                                    REGULATIONS_CONSTANTS.SEARCH_PLACEHOLDER
                                  }
                              />
                            </FormControl>
                          </Grid>
                          )}
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                          >
                            <DownloadTemplateButton />
                            <UploadPartListButton />
                          </Grid>
                        </div>
                        {filteredRegulatedParts
                            && filteredRegulatedParts.parts
                            && filteredRegulatedParts.parts.length > 0 && (
                              <div className="uploaded-parts-table">
                                <DataGrid
                                  rows={filteredRegulatedParts.parts}
                                  columns={partsTableColumns}
                                  initialState={{
                                    pagination: {
                                      paginationModel: {
                                        pageSize: 10,
                                      },
                                    },
                                  }}
                                  pageSizeOptions={[10]}
                                  disableRowSelectionOnClick
                                  disableColumnSelector
                                  disableColumnMenu
                                  autoHeight
                                  classes={{ withBorderColor: "#001489" }}
                                  getRowClassName={getRowClassName}
                                />
                              </div>
                        )}
                      </div>

                      {partsSearchString
                        && filteredRegulatedParts
                        && filteredRegulatedParts.parts
                        && filteredRegulatedParts.parts.length === 0 && (
                          <Alert severity="info">
                            {REGULATIONS_CONSTANTS.NO_SEARCH_PARTS_RESULTS}
                          </Alert>
                      )}
                      {!partsSearchString
                        && filteredRegulatedParts
                        && filteredRegulatedParts.parts
                        && filteredRegulatedParts.parts.length === 0 && (
                          <Alert severity="info">
                            {REGULATIONS_CONSTANTS.NO_PARTS_AVAILABLE}
                          </Alert>
                      )}
                    </div>
                  </div>
                )}
                <div className="stepper-actions">
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Button
                      className={(!isFormUpdated || (invalidRows.size > 0 || showApiFailure) || isValidationInProgress) ? "continue-btn-disabled" : "continue-btn"}
                      variant="contained"
                      onClick={handleUpdateAndSubmit}
                      disabled={!isFormUpdated || (invalidRows.size > 0 || showApiFailure)
                        || isValidationInProgress}
                    >
                      { GENERIC_CONSTANTS.UPDATE}
                    </Button>
                  </Grid>
                </div>
              </div>
            </Grid>
          </div>
        )}
        {isDataLossAlert && (
          <div>
            <Dialog
              open={isDataLossAlert}
              onClose={() => handleDialogOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
                {REGULATIONS_CONSTANTS.DATA_DISCARD_ALERT_TITLE}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" className="alert-dialog-description">
                  {REGULATIONS_CONSTANTS.DATA_DISCARD_ALERT_MSG}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className="dialog-button-primary" variant="contained" onClick={() => handleDataDiscard(false)}>{GENERIC_CONSTANTS.DISCARD}</Button>
                <Button className="dialog-button-secondary" variant="contained" onClick={() => handleDialogOpen(false)} autoFocus>
                  {GENERIC_CONSTANTS.CANCEL}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default updatedRegulations;
