const UPLOAD_CONSTANTS = {
  CHUNK_SIZE: 50,
  CATALOG_PATH: "/proxycatalog/getCatalogData",
  STATUS: {
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    PARTIAL_FAILURE: "PARTIAL_FAILURE",
  },
  MESSAGES: {
    EMPTY: "",
    CATALOG_ERROR: "Catalog item lookup failed.",
    UNEXPECTED_ERROR: "Unexpected error occurred while processing.",
    SUCCESS: (count) => `${count} parts loaded. Click submit to process parts.`,
    FAILURE: (invalidCount) => `${invalidCount} parts not loaded. Revisit your parts and try to upload again.`,
    PARTIAL_FAILURE: (invalidCount, totalCount) => `${invalidCount} out of ${totalCount} parts not loaded correctly. Revisit your parts and try to upload again.`,
  },
};

export default UPLOAD_CONSTANTS;
